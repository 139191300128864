<template>
  <PxHeader :activeSearchMobile="false" />
  <section v-if="isBusy" class="containerGifLoading">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>
  <section v-else class="bg-agrogo-green-2">
    <!-- BOTON FLOTANTE PUBLICAR FINCA  -->
    <publish-button />
    <!-- CONTENEDOR NUMERACION -->
    <div class="bg-agrogo-yellow-1 h-14 flex lg:h-16">
      <div class="flex items-center w-2/3 md:w-3/5 lg:w-3/5 justify-center">
        <a class="font-nexa text-agrogo-green-2 md:text-2xl">
          <div class="style-info" v-html="dataQuestions?.title"></div>
        </a>
      </div>
    </div>
    <!-- COTENEDOR PREGUNTAS -->
    <div
      class="flex flex-col px-9 py-6 md:py-20 md:px-32 lg:px-44"
      v-if="dataQuestions?.page_content"
    >
      <!-- PREGUNTAS -->
      <div
        v-for="item in loadChilds(dataQuestions.page_content)"
        :key="item.id"
      >
        <div class="border-t-2 border-agrogo-gray-2 my-panel"></div>
        <div class="px-2 md:px-0 py-4 md-py-0 md:pt-4 md:mb-8 my-panel qna">
          <div
            class="flex justify-between md:justify-start font-nexa font-normal text-white text-left text-base focus:text-agrogo-yellow-1 cursor-pointer md:text-lg lg:text-xl my-panel question"
          >
            <div
              class="style-info ml-2 md:ml-0 text-title-question"
              v-html="item.content"
            ></div>
          </div>
          <div class="answer" style="height: 0px">
            <div class="grid grid-cols-1 md:pl-5 mt-5 my-panel z-0">
              <div v-if="item.childs.length > 0">
                <div v-for="child in item.childs" :key="child.id">
                  <div
                    class="text-white text-xs text-content-question font-dinpro md:text-base text-left style-info style-question-btn mb-5 md:ml-3"
                    v-html="child.content"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PxHeader from "@/components/header/PxHeader";
import { generateHash } from "@/utils";
import PublishButton from "@/components/publishButton/PublishButton";

export default {
  name: "FrequentlyAskedQuestions",
  inject: ["provider"],
  components: {
    PxHeader,
    PublishButton,
  },
  data() {
    return {
      /**
       * Variable that allows access to the methods of the provider publishFarmRepository
       * @type {Object}
       */
      repository: this.provider.publishFarmRepository,
      dataQuestions: null,
      /**
       * Variable that enables or disables the loading gif
       * @type {Boolean}
       */
      isBusy: null,
    };
  },
  methods: {
    /**
     * enable or disable the question the question container select to view its information.
     * @returns  {void}
     */
    openCloseQuestion() {
      let question = document.querySelectorAll(".question");

      question.forEach((qsitem) => {
        qsitem.addEventListener("click", function () {
          //   store the .answer div containing the answer
          let sibling = qsitem.nextElementSibling;

          // Nested loop for removing active class from all and set answer height to 0
          question.forEach((item) => {
            item.nextElementSibling.style.height = "0px";
            //   remove class "active" except for the currently clicked item
            item != qsitem && item.parentNode.classList.remove("active");
          });
          //then toggle the "active" class of clicked item's parent ".qna"
          this.parentNode.classList.toggle("active");

          // set actual height for .answer div if .qna has the class "active"
          if (qsitem.parentNode.classList.contains("active")) {
            sibling.style.height = sibling.scrollHeight + "px";
          } else {
            sibling.style.height = "0px";
          }
        });
      });
    },
    /**
     * Query the agrogo api and obtain the information of the questions.
     * @returns  {void}
     */
    async getQuestions() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.getAllNames("QUESTIONS_ANSWERS", dataAditional);
        me.dataQuestions = data;
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy = false;
      }
    },
    loadChilds(items) {
      let childs = [];
      childs = items.find((el) => el.name_id == "PQR_GROUP")?.childs;
      return childs;
    },
  },
  async mounted() {
    const me = this;
    window.scroll(0, 0);
    await me.getQuestions();
    me.openCloseQuestion();
  },
};
</script>

<style lang="css" scoped>
.text-content-question {
  font-weight: 400;
  line-height: 32px !important;
}

.text-title-question {
  font-weight: 400;
  line-height: 19px !important;
}

@media (max-width: 767px) {
  .text-content-question {
    font-weight: 400;
    line-height: 19px !important;
  }
  .qna .question::after {
    content: "▶";
    color: #f5ce35;
    display: inline-block;
    transition: transform 0.3s;
  }
  .qna.active .question::after {
    transform: rotate(90deg);
  }
}
@media (min-width: 768px) {
  .qna .question::before {
    content: "▶";
    color: #f5ce35;
    display: inline-block;
    margin-right: 15px;
    margin-top: -6px;
    transition: transform 0.3s;
  }
  .qna.active .question::before {
    transform: rotate(90deg);
  }
}
.qna {
  padding: 0;
}
.qna.active .question .text-title-question {
  color: #f5ce35;
}
.question {
  color: #333;
  cursor: pointer;
  user-select: none;
  transition: color 0.3s;
}
.question:hover {
  color: #000;
}

.qna .answer {
  transition: 0.3s linear;
  overflow: hidden;
}
</style>
